<template>
    <v-dialog v-model="dialog" max-width="700">
        <template v-slot:activator="{ on }">
            <span class="secondary--text subtitle-2" v-on="on">
                <a>{{ truncateText(textToDisplay, truncationLength) }}</a>
            </span>
        </template>
        <v-card style="overflow-y: hidden;">
            <v-card-title>
                <div class="primary--text">
                    <v-icon class="mr-1">mdi-script-text-outline</v-icon>
                    {{ title }}
                </div>
            </v-card-title>
            <v-divider class="mb-1"></v-divider>
            <v-card-text>
                <span class="subtitle-2">{{ textToDisplay }}</span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <CopyToClipboard
                    :textToCopy="textToDisplay"
                    buttonClass=""
                    buttonColor="secondary"
                    :isTextButton="true"
                    buttonName="copy to clipboard"
                    :showIcon="false"
                    :isSmall="false"
                ></CopyToClipboard>
                <v-btn color="secondary" text @click="pasteIntoConsole(textToDisplay)">paste to console</v-btn>
                <v-btn color="primary" text @click="dialog = false">Close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { truncateText } from '@/utils'
const CopyToClipboard = () => import('@/components/CopyToClipboard')

export default {
    name: 'QueryDialog',
    components: { CopyToClipboard },
    props: {
        title: String,
        textToDisplay: String,
        truncationLength: Number,
        mode: String,
        timePassed: Number,
        status: String,
        query: String,
        qid: String
    },
    data() {
        return {
            dialog: false
        }
    },
    methods: {
        truncateText: function(text, maxLength) {
            return truncateText(text, maxLength)
        },
        pasteIntoConsole(sql) {
            this.$emit('sqlText', { value: sql })
            this.$data.dialog = false
        }
    }
}
</script>
