var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "700" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "span",
                _vm._g({ staticClass: "secondary--text subtitle-2" }, on),
                [
                  _c("a", [
                    _vm._v(
                      _vm._s(
                        _vm.truncateText(
                          _vm.textToDisplay,
                          _vm.truncationLength
                        )
                      )
                    )
                  ])
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticStyle: { "overflow-y": "hidden" } },
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "primary--text" },
              [
                _c("v-icon", { staticClass: "mr-1" }, [
                  _vm._v("mdi-script-text-outline")
                ]),
                _vm._v(" " + _vm._s(_vm.title) + " ")
              ],
              1
            )
          ]),
          _c("v-divider", { staticClass: "mb-1" }),
          _c("v-card-text", [
            _c("span", { staticClass: "subtitle-2" }, [
              _vm._v(_vm._s(_vm.textToDisplay))
            ])
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c("CopyToClipboard", {
                attrs: {
                  textToCopy: _vm.textToDisplay,
                  buttonClass: "",
                  buttonColor: "secondary",
                  isTextButton: true,
                  buttonName: "copy to clipboard",
                  showIcon: false,
                  isSmall: false
                }
              }),
              _c(
                "v-btn",
                {
                  attrs: { color: "secondary", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.pasteIntoConsole(_vm.textToDisplay)
                    }
                  }
                },
                [_vm._v("paste to console")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }